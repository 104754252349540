import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";

import { ACCOMMODATION_TYPE, UNIT, getHideWidgetClass, isClientLoggedIn } from "../../../../components/utils";

import { DynamicFilter } from "../../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../../dynamicWidget.types";
import { State } from "../../../../redux";
import { WidgetOptions } from "./";
import { connect } from "react-redux";
import { getMxtsEnv } from "../../../mxts";
import { wrapProps } from "../../../../i18n";

export interface OverviewNameProps extends OverviewNameBaseProps, OverviewNameStoreProps {}

interface OverviewNameBaseProps extends DynamicWidgetBaseProps<WidgetOptions> {
    className?: string;
}

interface OverviewNameState {
    name: string;
    disableWidget: boolean;
}

interface OverviewNameStoreProps {
    dynamicFilter: DynamicFilter;
}

class OverviewNameBaseWidget extends React.Component<OverviewNameProps, OverviewNameState> {
    constructor(props: OverviewNameProps) {
        super(props);
        this.state = {
            name: "",
            disableWidget: true,
        };
    }

    public componentDidMount() {
        const { context, dynamicFilter, options } = this.props;
        const resourceIds = dynamicFilter.resourceid ? [dynamicFilter.resourceid] : dynamicFilter.resourceids;
        getMxtsEnv(context, context.currentLocale.code).then(async (env) => {
            if (dynamicFilter) {
                if (options.contentType === ACCOMMODATION_TYPE && resourceIds?.length) {
                    const resource = await context.mxtsApi.resources(env, { size: 1, resourceIds }).then((res: MXTS.PagedResult<MXTS.Resource>) => res.content);
                    this.setState({ name: resource[0].name });
                } else if (options.contentType === UNIT && dynamicFilter.unitid) {
                    const unit = await context.mxtsApi.units(env, { size: 1, unitIds: [dynamicFilter.unitid] }).then((res: MXTS.PagedResult<MXTS.Unit>) => res.content);
                    this.setState({ name: unit[0].name });
                }
            }
        });
        this.setState({ disableWidget: !isClientLoggedIn() });
    }

    public render(): JSX.Element | null {
        const { className, options } = this.props;
        const hideWidget = getHideWidgetClass(this.props.options, this.state.disableWidget);
        if (hideWidget === null) {
            return null;
        }
        const styles: any = {
            // eslint-disable-next-line max-len
            color: options.descFontColor && options.descFontColor.indexOf("rgba") > -1 ? options.descFontColor : undefined,
            borderColor: options.nameStyle === "row-bordered" && options.nameTextUndrlinedColor && options.nameTextUndrlinedColor.indexOf("rgba") > -1 ? options.nameTextUndrlinedColor : undefined,
            // eslint-disable-next-line max-len
            backgroundColor: options.nameStyle === "background-text" && options.nameTextBackground && options.nameTextBackground.indexOf("rgba") > -1 ? options.nameTextBackground : undefined,
        };
        return (
            <div
                // eslint-disable-next-line max-len
                className={`overview name ${className} ${hideWidget} ${options.descFontSize ? options.descFontSize : ""} ${
                    options.descFontColor && options.descFontColor.indexOf("theme") > -1
                        ? `color-${options.descFontColor}`
                        : options.descFontColor && options.descFontColor.indexOf("rgba") === -1
                        ? options.descFontColor
                        : ""
                } ${options.descFontStyle ? options.descFontStyle : ""} border-width-${
                    options.nameStyle === "row-bordered" && options.nameTextUndrlinedSize ? options.nameTextUndrlinedSize + " pb-1 border-bottom-style-solid" : ""
                } m-0 ${options.nameStyle === "background-text" && options.nameTextBackground ? "p-2" : ""}`}
                style={styles}
            >
                {this.state.name}
            </div>
        );
    }
}

function mapStateToProps(state: State): OverviewNameStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
    };
}

const OverviewName = connect<OverviewNameStoreProps>(mapStateToProps)(OverviewNameBaseWidget);

export const OverviewNameWidget = wrapProps<OverviewNameBaseProps>(OverviewName);
