import * as React from "react";

import { ACCOMMODATION_TYPE, I18nACCOMMODATIONTYPE, I18nUNIT, NONE, UNIT } from "../../../../components/utils";
import { FormSpec, multiSelectStylePicker } from "../../../../form-specs";

import { OverviewNameWidget } from "./OverviewNameWidget";
import { PageWidgetSpec } from "../../../";
import { WidgetGroup } from "../../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../../themes";
import { getI18nLocaleObject } from "../../../../i18n";
import namespaceList from "../../../../i18n/namespaceList";

export interface WidgetOptions {
    contentType: string | null;
    styleIds: string[];
    descFontSize: string;
    descFontColor: string;
    descFontStyle: string;
    nameStyle: string;
    nameTextBackground: string;
    nameTextUndrlinedSize: string;
    nameTextUndrlinedColor: string;
}

const TARGETS = ["overview-name"];

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "dynamic-overview-name-widget",
    name: getI18nLocaleObject(namespaceList.widgetOverviewPanel, "overviewName"),
    pluralName: getI18nLocaleObject(namespaceList.widgetOverviewPanel, "overviewNames"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                variable: "contentType",
                                type: "select",
                                optionList: [
                                    {
                                        value: null,
                                        label: NONE,
                                    },
                                    {
                                        value: UNIT,
                                        label: I18nUNIT,
                                    },
                                    {
                                        value: ACCOMMODATION_TYPE,
                                        label: I18nACCOMMODATIONTYPE,
                                    },
                                ],
                                placeholder: getI18nLocaleObject(namespaceList.admin, "contentPlaceholder"),
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                variable: "descFontSize",
                                label: getI18nLocaleObject(namespaceList.widgetOverviewPanel, "nameFontSize"),
                                type: "select",
                                default: "default-font-size",
                                optionList: [
                                    {
                                        value: "default-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "defaultFontSize"),
                                    },
                                    {
                                        value: "micro-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "microFontSize"),
                                    },
                                    {
                                        value: "xx-small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "xxSmallFontSize"),
                                    },
                                    {
                                        value: "x-small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "xSmallFontSize"),
                                    },
                                    {
                                        value: "small-font-size",
                                        label: getI18nLocaleObject(namespaceList.admin, "smallFontSize"),
                                    },
                                    {
                                        value: "h6-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h6Fonts"),
                                    },
                                    {
                                        value: "h5-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h5Fonts"),
                                    },
                                    {
                                        value: "h4-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h4Fonts"),
                                    },
                                    {
                                        value: "h3-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h3Fonts"),
                                    },
                                    {
                                        value: "h2-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h2Fonts"),
                                    },
                                    {
                                        value: "h1-text",
                                        label: getI18nLocaleObject(namespaceList.admin, "h1Fonts"),
                                    },
                                ],
                            },
                            {
                                variable: "descFontColor",
                                label: getI18nLocaleObject(namespaceList.admin, "fontColor"),
                                type: "dual-color",
                                default: "font-default",
                            },
                            {
                                variable: "descFontStyle",
                                label: getI18nLocaleObject(namespaceList.admin, "descFontStyle"),
                                type: "select",
                                default: "default",
                                optionList: [
                                    {
                                        value: "default",
                                        label: getI18nLocaleObject(namespaceList.admin, "default"),
                                    },
                                    {
                                        value: "text-uppercase",
                                        label: getI18nLocaleObject(namespaceList.admin, "allCaps"),
                                    },
                                    {
                                        value: "text-capitalize",
                                        label: getI18nLocaleObject(namespaceList.admin, "camelCase"),
                                    },
                                    {
                                        value: "text-lowercase",
                                        label: getI18nLocaleObject(namespaceList.admin, "lowerCase"),
                                    },
                                ],
                            },
                            {
                                variable: "nameStyle",
                                label: getI18nLocaleObject(namespaceList.widgetOverviewPanel, "nameStyle"),
                                type: "select",
                                optionList: [
                                    {
                                        value: "background-text",
                                        label: getI18nLocaleObject(namespaceList.widgetOverviewPanel, "backgroundText"),
                                    },
                                    {
                                        value: "row-bordered",
                                        label: getI18nLocaleObject(namespaceList.admin, "rowBordered"),
                                    },
                                ],
                            },
                            {
                                variable: "nameTextBackground",
                                label: getI18nLocaleObject(namespaceList.widgetOverviewPanel, "nameTextBackground"),
                                type: "dual-color",
                                default: "background-gray",
                                visible: (options: WidgetOptions) => options.nameStyle === "background-text",
                            },
                            {
                                variable: "nameTextUndrlinedSize",
                                label: getI18nLocaleObject(namespaceList.widgetOverviewPanel, "nameTextUndrlinedSize"),
                                type: "text",
                                visible: (options: WidgetOptions) => true && options.nameStyle === "row-bordered",
                            },
                            {
                                variable: "nameTextUndrlinedColor",
                                label: getI18nLocaleObject(namespaceList.widgetOverviewPanel, "nameTextUndrlinedColor"),
                                type: "dual-color",
                                default: "default",
                                visible: (options: WidgetOptions) => true && options.nameStyle === "row-bordered",
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

export const overviewNameWidget: PageWidgetSpec<WidgetOptions> = {
    id: "overviewNameWidget",
    type: "page",
    widgetGroup: WidgetGroup ? WidgetGroup.BOOKINGS_MODULE : 4,
    name: getI18nLocaleObject(namespaceList.widgetOverviewPanel, "overviewName"),
    description: getI18nLocaleObject(namespaceList.widgetOverviewPanel, "overviewNameDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        contentType: null,
        styleIds: [],
        descFontSize: "default-font-size",
        descFontColor: "font-default",
        descFontStyle: "default",
        nameStyle: "",
        nameTextBackground: "",
        nameTextUndrlinedSize: "",
        nameTextUndrlinedColor: "font-default",
    }),
    async render(widget, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions) {
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        return <OverviewNameWidget dynamicContainerOptions={dynamicContainerOptions} options={widget.options} context={context} className={styleClasses} />;
    },
};
